import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/sun/sunproducts/sensitive-mineral-product-front.webp";
import backImage from "images/sun/sunproducts/sensitive-mineral-product-back.webp";
import frontZoomImage from "images/sun/sunproducts/sensitive-mineral-product-front-zoom.webp";
import backZoomImage from "images/sun/sunproducts/sensitive-mineral-product-back-zoom.webp";
import thirdZoomImage from "images/sun/sunproducts/sensitive-mineral-third-zoom.webp";
import thirdImage from "images/sun/sunproducts/sensitive-mineral-third.webp";
import img1 from "images/sun/sunproducts/sensitive-mineral-use-survey.webp";
import img4 from "images/sun/sunproducts/sensitive-mineral-benefits-survey.webp";

import img9 from "images/callouts/cout-footer-dry-skin-therapy.webp";
import img10 from "images/callouts/cout-footer-eczema.webp";
import img11 from "images/callouts/cout-footer-journal.webp";

import "./sensitivemineral.scss";

function SensitiveMineralPage({ pageContext }) {
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-sensitive-mineral page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Sun Tinted Sensitive Mineral"
        pageDescription="Learn about Eucerin Sun Tinted Sensitive Mineral for all skin types and tones"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3" style={{marginBottom: 0}}>
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#E07810"
              categoryName="SUN CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Sun Sensitive Mineral"
              productSlider={[frontImage]}
              zoomImages={[frontZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Protects sensitive skin with a soft, smooth texture
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Sun Sensitive Mineral
                  </span>{" "}
                  is formulated with 100% naturally sourced zinc oxide*
                  protection as the active ingredient for gentle,
                  hypoallergenic, broad-spectrum, photostable sun protection.
                </p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Formulated with 24% zinc oxide
                      </span>
                    </span>
                  </li>
                  <li>
                    <span>Gentle and non-irritating</span>
                  </li>
                  <li>
                    <span>Patients appreciate its silky texture</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Active ingredient
                </p>
                <p className="no-mb font-semi-bold">24% zinc oxide</p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>
                      Provides broad-spectrum protection for sensitive skin
                    </span>
                  </li>
                  <li>
                    <span>
                      Formulated to be gentle and <nobr>non-irritating</nobr>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold mango-tango-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">5 AOX Shield™ helps to:</p>
                <ul className="mango-tango-bullets">
                  <li>
                    <span>Neutralize free radicals</span>
                  </li>
                  <li>
                    <span>Guard against free radical formation</span>
                  </li>
                  <li>
                    <span>Defend against natural antioxidant breakdown</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold mango-tango-text sub-title">
                    Formulated for sensitive skin
                  </p>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Non-comedogenic</span>
                    </li>
                    <li>
                      <span>Ophthalmologist-tested</span>
                    </li>
                  </ul>
                  <ul className="checkmarks mango-tango">
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                    <li>
                      <span>Octinoxate-free</span>
                    </li>
                    <li>
                      <span>Oxybenzone-free</span>
                    </li>
                  </ul>
                </div>
                <p className="footnotes last">*Based on zinc oxide active.</p>
              </Col>
            </Row>
          </section>
        </div>
        {/*<div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="USE SURVEY"
              productThemeColor="sun-mango-tango"
              id="usesurvey"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img1}
                    className="accordion-chart"
                    alt="Real-world patient feedback on ease of application"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Blends easily on <br className="show-desktop" /> patients’
                    skin
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="BENEFITS SURVEY"
              productThemeColor="sun-mango-tango"
              id="benefitssurvey"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Real-world patient feedback on product benefits"
                  />
                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Protects patients <br className="show-desktop" /> with
                    sensitive skin
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>*/}
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img9}
                  imgAlt="Hydration"
                  caption="Recommend daily hydration <br class='show-desktop' /> along with sun protection"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img10}
                  imgAlt="ECZEMA REGIMEN"
                  caption="Choose a proactive therapeutic regimen for eczema"
                  buttonText="ECZEMA REGIMEN"
                  buttonUrl="/body/eczema"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img11}
                  imgAlt="JAAD"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 165 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default SensitiveMineralPage;
